import React, { Component } from "react"
import PropTypes from "prop-types"
import ReactPaginate from "react-paginate"

const noticeData = [
  {
    title: "외부감사인 선임 공고",
    url: "/notice/16",
    date: "2024-02-20",
  },
  {
    title: "신주발행공고(제3자배정)",
    url: "/notice/15",
    date: "2023-12-21",
  },
  {
    title: "개발 및 제작 용역 공모",
    url: "/notice/14",
    date: "2023-10-23",
  },
  {
    title: "신주발행공고(제3자배정)",
    url: "/notice/13",
    date: "2023-08-04",
  },
  {
    title: "외부감사인 선임 공고",
    url: "/notice/12",
    date: "2023-02-14",
  },
  {
    title: "임시주주총회 소집을 위한 명의개서 정지의 건",
    url: "/notice/11",
    date: "2022-11-16",
  },
  {
    title: "외부감사인 변경 선임 공고",
    url: "/notice/10",
    date: "2022-11-03",
  },
  {
    title: "신주발행공고(제3자배정)",
    url: "/notice/9",
    date: "2022-10-19",
  },
  {
    title: "외부감사인 선임 공고",
    url: "/notice/8",
    date: "2022-02-17",
  },
  {
    title: "신주발행공고(제3자배정)",
    url: "/notice/7",
    date: "2022-02-11",
  },
  {
    title: "임시주주총회 소집을 위한 명의개서 정지의 건",
    url: "/notice/6",
    date: "2021-10-29",
  },
  {
    title: "신주발행공고(제3자배정)",
    url: "/notice/5",
    date: "2021-09-14",
  },
  {
    title: "임시주주총회 소집을 위한 명의개서 정지의 건",
    url: "/notice/4",
    date: "2021-06-28",
  },
  {
    title: "[인증] ICT 규제 샌드박스 임시허가 부여 안내",
    url: "/notice/3",
    date: "2020-08-07",
  },
  {
    title: "임시주주총회 소집을 위한 명의개서 정지의 건",
    url: "/notice/2",
    date: "2020-06-12",
  },
  {
    title: "신주발행공고(내용변경)",
    url: "/notice/1",
    date: "2020-01-30",
  },
]

class NoticeList extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
  }

  render() {
    let noticeNodes = this.props.data.map((notice, index) => {
      return (
        <div key={index} className="sf-board-table-row">
          <div className="sf-board-table-cell-1st">
            <a href={notice.url} rel="noopener noreferrer">
              {notice.title}
            </a>
          </div>
          <div className="sf-board-table-cell-2nd sf-board-table-cell-mobile">
            <span className="sf-icon-arrow-right"></span>
          </div>
          <div className="sf-board-table-cell-2nd sf-board-table-cell-web">{notice.date}</div>
        </div>
      )
    })

    return noticeNodes
  }
}

class NoticePagination extends Component {
  perPage = 5
  state = {
    pageCount: noticeData.length / this.perPage,
    data: noticeData.slice(0, this.perPage),
    offset: 0,
  }

  handlePageClick = data => {
    const offset = Math.ceil(data.selected * this.perPage)

    this.setState({
      offset: offset,
      data: noticeData.slice(offset, offset + this.perPage),
    })
  }

  render() {
    return (
      <div className="content">
        <div className="title">공고 / 공지</div>
        <div className="page-area">
          <div className="sf-board-table">
            <div className="sf-board-table-head">
              <div className="sf-board-table-cell">내용</div>
              <div className="sf-board-table-cell">등록일</div>
            </div>
            <NoticeList data={this.state.data} />
          </div>
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            pageCount={this.state.pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.handlePageClick}
            containerClassName={"Page pagination"}
            activeClassName={"active"}
          />
        </div>
      </div>
    )
  }
}

export default NoticePagination
