import React, { useState, useEffect } from "react"
import ReactModal from "react-modal"

import "../styles/notice-popup.css"

ReactModal.setAppElement("#___gatsby")

const NoticePopup2 = ({ open = false }) => {
  const [poupOpen, setPopupOen] = useState(open)

  useEffect(() => {
    if (poupOpen) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "unset"
    }
  }, [poupOpen])

  const onCloseModal = () => {
    setPopupOen(false)
  }

  return (
    <ReactModal isOpen={poupOpen} onRequestClose={onCloseModal} className="NoticePopup" contentLabel="공고/공지">
      <div className="noticePage">
        <div className="content">
          <div className="noticeHeader" style={{ paddingTop: 0, textAlign: "center" }}>
            공고 / 공지
          </div>
          <div className="noticeBody" style={{ margin: "10px 0" }}>
            <div className="textArea" style={{ padding: "30px 10px" }}>
              <div className="contentsArea">
                <div className="textTitle">외부감사인 선임 공고</div>
                <div className="textDesc">
                  <p>
                    주식회사 등의 외부감사에 관한 법률 제12조 1항 및 동법 시행령 제18조 1항에 의거, 당사의 외부감사인 선임사실을
                    다음과 같이 공고합니다.
                  </p>
                  <br />
                  <p>
                    1. 외부감사인 : 삼일회계법인
                    <br />
                    2. 대상기간 : 2023년 1월 1일 ~ 2023년 12월 31일
                    <br />
                  </p>
                  <br />
                  <br />
                  <p className="footer">
                    2023년 2월 14일
                    <br />
                    주식회사 스테이지파이브 대표이사 서 상 원<br />
                    서울특별시 송파구 올림픽로35 123, 6층(신천동, 향군타워)
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div style={{ width: "100%", textAlign: "center" }}>
            <button style={{ fontSize: "15px", width: "70px", height: "30px" }} onClick={onCloseModal}>
              닫기
            </button>
          </div>
        </div>
      </div>
    </ReactModal>
  )
}

export default NoticePopup2
