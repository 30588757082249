import React from "react"
import Layout from "../components/layout"
import NoticePopup from "../components/notice-popup"
import NoticePopup2 from "../components/notice-popup2"
import NoticePagination from "../components/notices"
import PressPagination from "../components/press"
import SEO from "../components/seo"
import "../styles/index.css"

const IndexPage = () => (
  <Layout>
    <SEO title="ABOUT" />
    <div className="mainContent">
      <div className="menu-index">
        <div className="section top-section">
          <div className="content">
            <div className="image">
              <img src="/images/index_top_bg_mobile_20221012.png" alt="Stage Five" />
            </div>
          </div>
        </div>
        <a
          href="https://stagefive.notion.site/STAGE-F1VE-ce4171c9a6ed419bb367e9dbcdfad9f9"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="section recruit-section">
            <div className="content">
              <div className="image">
                <img src="/images/index_recruit_banner_mw_220517.png" alt="Pindirectshop" />
              </div>
            </div>
          </div>
        </a>
        <div className="section intro-section">
          <div className="content">
            <div className="sf-info-table">
              <div className="sf-info-table-cell-image sf-info-table-cell-web">
                <img src="/images/index_intro_5g_service.png" alt="5G service" />
              </div>
              <div className="sf-info-table-cell-text">
                <div className="title">
                  <span>5G</span> service
                </div>
                <div className="desc">
                  스테이지파이브는 어렵고 복잡한 통신 서비스를
                  <br />
                  최소한의 단계로 더 쉽고 빠르게 이용할 수 있도록
                  <br />
                  최적화된 간편 통신 유통 플랫폼 서비스를 제공하며
                  <br />
                  5G 시대에 새로운 통신 경험을 제시합니다.
                </div>
              </div>
              <div className="sf-info-table-cell-image sf-info-table-cell-mobile">
                <img src="/images/index_intro_5g_service.png" alt="5G service" />
              </div>
            </div>
            <div className="sf-info-table">
              <div className="sf-info-table-cell-text">
                <div className="title">
                  <span>5G</span> plan
                </div>
                <div className="desc">
                  스테이지파이브는 고객에게 가장 합리적인 상품을
                  <br />
                  제공하기 위해 5G와 다양한 서비스 및 혜택을 결합하여
                  <br />
                  새로운 형태의 5G 통신 상품을 제공합니다.
                </div>
              </div>
              <div className="sf-info-table-cell-image">
                <img src="/images/index_intro_5g_plan.png" alt="5G plan" />
              </div>
            </div>
            <div className="sf-info-table">
              <div className="sf-info-table-cell-image sf-info-table-cell-web">
                <img src="/images/index_intro_5g_device.png" alt="5G device" />
              </div>
              <div className="sf-info-table-cell-text">
                <div className="title">
                  <span>5G</span> device
                </div>
                <div className="desc">
                  스테이지파이브는 현시대의 트랜드와 고객이 요구하는
                  <br />
                  디바이스 환경에 대해 항상 고민하고, 고객 친화적인
                  <br />
                  UX/UI와 다양한 콘텐츠가 결합된 5G 전용 디바이스를 개발
                  <br />
                  하며, 통신사와 신뢰 있는 검증을 통해 제공합니다.
                </div>
              </div>
              <div className="sf-info-table-cell-image sf-info-table-cell-mobile">
                <img src="/images/index_intro_5g_device.png" alt="5G device" />
              </div>
            </div>
            {/* <div className="sf-info-table">
              <div className="sf-info-table-cell-text">
                <div className="title">
                  with<span> kakao</span>
                </div>
                <div className="desc">
                  스테이지파이브는 대한민국 최대 모바일 플랫폼인
                  <br />
                  카카오(kakao)와 함께하는 회사입니다.
                  <br />
                  카카오의 수많은 서비스 콘텐츠와 다양한 혜택은
                  <br />
                  5G 통신 서비스에 새로운 가치를 창출합니다.
                </div>
              </div>
              <div className="sf-info-table-cell-image">
                <img src="/images/index_intro_with_kakao.png" alt="with kakao" />
              </div>
            </div> */}
          </div>
        </div>
        <a
          href="https://www.pindirectshop.com?utm_campaign=organic&utm_source=stagefive&utm_medium=banner"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="section product-section">
            <div className="content">
              <div className="image">
                <img src="/images/index_pindirectshop_bg_mobile_20220711.png" alt="Pindirectshop" />
              </div>
            </div>
          </div>
        </a>
        <div className="section press-section">
          <PressPagination />
        </div>
        <div className="section press-section">
          <NoticePagination />
        </div>
      </div>
    </div>
    {/* <NoticePopup open={true} /> */}
  </Layout>
)

export default IndexPage
