import React, { useState, useEffect } from "react"
import ReactModal from "react-modal"

import "../styles/notice-popup.css"

ReactModal.setAppElement("#___gatsby")

const NoticePopup = ({ open = false }) => {
  const [poupOpen, setPopupOen] = useState(open)

  useEffect(() => {
    if (poupOpen) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "unset"
    }
  }, [poupOpen])

  const onCloseModal = () => {
    setPopupOen(false)
  }

  return (
    <ReactModal isOpen={poupOpen} onRequestClose={onCloseModal} className="NoticePopup" contentLabel="공고/공지">
      <div className="noticePage">
        <div className="content">
          <div className="noticeHeader" style={{ paddingTop: 0, textAlign: "center" }}>
            공고 / 공지
          </div>
          <div className="noticeBody" style={{ margin: "10px 0" }}>
            <div className="textArea" style={{ padding: "30px 10px" }}>
              <div className="contentsArea">
                <div className="textTitle">신주발행공고</div>
                <div className="textDesc">
                  <p>
                    2023년 8월 4일 개최한 주식회사 스테이지파이브 이사회에서 아래와 같이 제3자배정 방법으로 신주발행을
                    결의하였음을 공고합니다.
                  </p>
                  <br />
                  <p>
                    1. 신주의 종류와 수 : 상환전환우선주식10 3,598 주 <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;상환전환우선주식11
                    10,000 주
                    <br />
                    2. 신주의 발행가액 : 금 277,908원 (1주의 금액 금5,000원)
                    <br />
                    3. 신주의 납입기일 : 2023년 8월 18일
                    <br />
                    4. 신주의 인수방법 : 상법 제418조 및 정관 제10조 2항의 규정에 의하여 기존주주의 신주인수권을 배제하고, 신주
                    전부를 제3자에게 배정한다
                    <br />
                  </p>
                  <br />
                  <br />
                  <p className="footer">
                    2023년 8월 4일
                    <br />
                    주식회사 스테이지파이브 대표이사 서 상 원<br />
                    서울특별시 송파구 올림픽로35 123, 6층(신천동, 향군타워)
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div style={{ width: "100%", textAlign: "center" }}>
            <button style={{ fontSize: "15px", width: "70px", height: "30px" }} onClick={onCloseModal}>
              닫기
            </button>
          </div>
        </div>
      </div>
    </ReactModal>
  )
}

export default NoticePopup
