import React, { Component } from "react"
import PropTypes from "prop-types"
import ReactPaginate from "react-paginate"

const pressData = [
  {
    title: "스테이지엑스, 28GHz 리얼 5G 데이터 ‘무료제공’ 계획 밝혀 ",
    url: "/press/53",
    date: "2024-04-01",
  },
  {
    title: "스테이지파이브, 알뜰폰 개통 명의도용 방지 노력 강화… 안랩과 함께 보안 점검",
    url: "/press/52",
    date: "2024-03-29",
  },
  {
    title: "스테이지엑스, 클라우드 코어망 인프라 구축 위한 업체선정 나서",
    url: "/press/51",
    date: "2024-03-27",
  },
  {
    title: "딥테크 통신사 스테이지엑스, “고객 관점에서 통신서비스 혁신” 할 것",
    url: "/press/50",
    date: "2024-02-07",
  },
  {
    title: "스테이지엑스, 제4이동통신사업자로 선정",
    url: "/press/48",
    date: "2024-01-31",
  },
  {
    title: "인텔리안테크의 지분 참여…”리얼 5G 혁신서비스 위한 협업 이어갈 것”",
    url: "/press/47",
    date: "2023-12-27",
  },
  {
    title: "[보도설명자료] ‘23.12.26 더일렉 보도 관련 설명자료",
    url: "/press/46",
    date: "2023-12-26",
  },
  {
    title: "5G(28㎓) 신규사업자 주파수 할당 신청",
    url: "/press/45",
    date: "2023-12-19",
  },
  {
    title: "스테이지파이브, 책임경영 의지 밝혀… 지배구조 개편",
    url: "/press/49",
    date: "2023-12-18",
  },
  {
    title: "핀다이렉트 로밍 內 현지망 확대... '이젠 일본여행도 현지망으로!'",
    url: "/press/44",
    date: "2023-11-16",
  },
  {
    title: "네이버클라우드와 업무협약 체결",
    url: "/press/43",
    date: "2023-11-07",
  },
  {
    title: "핀다이렉트 내 3사망 도입",
    url: "/press/42",
    date: "2023-11-02",
  },
  {
    title: "핀다이렉트 로밍패스 모델로 '빠니보틀' 발탁... 몰입도를 높인 드라마타이즈형 광고 영상 공개",
    url: "/press/41",
    date: "2023-09-27",
  },
  {
    title: "구독형 로밍서비스 '로밍패스'출시",
    url: "/press/40",
    date: "2023-09-06",
  },
  {
    title: "밀크파트너스와 MOU체결... '유저 혜택 극대화 및 사업영역 확대 기대'",
    url: "/press/39",
    date: "2023-08-29",
  },
  {
    title: "스테이지파이브, 야놀자와 협업 강화...'데이터 걱정 없이 야놀자!'",
    url: "/press/38",
    date: "2023-08-13",
  },
  {
    title: "해외 어디에 있어도 핀다이렉트 로밍 고객은 이상 무!",
    url: "/press/37",
    date: "2023-05-23",
  },
  {
    title: "스테이지파이브, 고객 서비스 품질 강화로 브랜드 신뢰 증진",
    url: "/press/36",
    date: "2023-05-19",
  },
  {
    title: "스테이지파이브, 카카오페이에서 통신서비스 시작",
    url: "/press/35",
    date: "2023-05-15",
  },
  {
    title: "스테이지파이브, AI간편개통 고도화 하여 개통 시 사전동의 불편 100% 해소, 고객센터 효율 70% 증진",
    url: "/press/34",
    date: "2023-04-25",
  },
  {
    title: "스테이지파이브, 카카오엔터프라이즈와 통신 업계 최초 AI개통시스템 구축",
    url: "/press/33",
    date: "2023-03-14",
  },
  {
    title:
      "내 폰 시세조회, 해외데이터로밍 이용, 내 요금 관리까지 앱 하나로… 올인원 통신앱 ‘핀다이렉트’ 출시",
    url: "/press/32",
    date: "2023-02-17",
  },
  {
    title: "핀다이렉트, 인터파크·트리플과 손잡고 140개국 로밍 데이터 무료 이벤트",
    url: "/press/31",
    date: "2022-12-21",
  },
  {
    title: "비엠스마일 - 스테이지파이브 합작법인, 비엠스테이지 출범",
    url: "/press/30",
    date: "2022-06-28",
  },
  {
    title: "알뜰폰상담도 카카오톡으로 스테이지파이브 고객 불편 해소 위해 상담 톡 업그레이드",
    url: "/press/29",
    date: "2022-03-21",
  },
  {
    title: "중고폰 시장 양성화하기 위한 합작사 나왔다",
    url: "/press/28",
    date: "2022-03-15",
  },
  {
    title: "스테이지파이브, 자사 핀다이렉트샵 내 카카오 인증서 도입",
    url: "/press/27",
    date: "2022-02-17",
  },
  {
    title: "핀다이렉트샵 1만 명 한정 아이폰 전용 요금제 출시",
    url: "/press/26",
    date: "2021-10-20",
  },
  {
    title: "아이폰13 자급제족 모여라...10월 한정, 핀다이렉트샵 아이폰13 풀케어 특별혜택 오픈",
    url: "/press/25",
    date: "2021-10-07",
  },
  {
    title: "“아이폰은 아이폰!”…핀다샵 아이폰13 사전예약 알림 신청, 아이폰12대비 220% 웃돌아",
    url: "/press/24",
    date: "2021-09-29",
  },
  {
    title: "추석맞이 통신요금 빅찬스…221GB같은 11GB 요금제에 백화점 상품권까지",
    url: "/press/23",
    date: "2021-09-16",
  },
  {
    title: "스테이지파이브, 15일부터 아이폰13 시리즈 사전 예약 알림 신청 진행",
    url: "/press/22",
    date: "2021-09-14",
  },
  {
    title: "스테이지파이브 알뜰폰 가입자에 인공지능 전화 비토 사용권 제공",
    url: "/press/21",
    date: "2021-09-08",
  },
  {
    title: "핀다이렉트샵, SPC그룹 해피포인트와 손잡고 MZ세대 대상 제휴 통신요금제 출시",
    url: "/press/20",
    date: "2021-08-30",
  },
  {
    title: "스테이지파이브, ‘리틀카카오프렌즈 크롬북’ 출시",
    url: "/press/19",
    date: "2021-08-27",
  },
  {
    title: "핀다이렉트샵도 고객께 성큼, “롯데하이마트에서 자급제폰도 사고, 알뜰폰 유심할인도 받고!",
    url: "/press/18",
    date: "2021-08-19",
  },
  {
    title: "핀다이렉트샵, 삼성전자 자급제폰 구매자 대상 제휴요금제 선보여",
    url: "/press/17",
    date: "2021-08-17",
  },
  {
    title: "스테이지파이브, 월 9천원 알뜰폰 무제한 요금제 출시",
    url: "/press/16",
    date: "2021-07-12",
  },
  {
    title: "제휴서비스 프로모션으로 진화하는 알뜰폰, “카드 실적 따라 청구할인도 OK",
    url: "/press/15",
    date: "2021-07-07",
  },
  {
    title: "알뜰폰과 명상 앱의 이색 콜라보!",
    url: "/press/14",
    date: "2021-06-29",
  },
  {
    title: "스테이지파이브 ‘핀다이렉트샵’ 알뜰폰 유심 요금제 출시 100일!",
    url: "/press/13",
    date: "2021-05-31",
  },
  {
    title: "안전하고 합리적인 중고폰 구매, ‘핀다이렉트 케어 중고폰’!",
    url: "/press/12",
    date: "2021-05-10",
  },
  {
    title: "스테이지파이브 핀다이렉트샵, 롯데카드 제휴로 통신료 월 최대 2만원 할인",
    url: "/press/11",
    date: "2021-04-30",
  },
  {
    title: "‘통신’과 ‘금융’의 의기투합, “유저 중심형 서비스 제공할 것”, 스테이지파이브ㆍ신한은행, 전략적 업무협약(MOU) 체결",
    url: "/press/10",
    date: "2021-04-06",
  },
  {
    title: "알뜰폰 5G유심도 핀다이렉트샵서 최저가로 ‘비대면’ 가입하세요",
    url: "/press/1",
    date: "2021-03-02",
  },
  {
    title: "MVNO(알뜰폰) 6개社 MOU, 26일 MVNO 활성화에 한 마음 한 뜻",
    url: "/press/9",
    date: "2021-03-01",
  },
  {
    title: "스테이지파이브-한국알뜰통신사업자협회, 알뜰폰 상생 위해 맞손",
    url: "/press/7",
    date: "2020-12-23",
  },
  {
    title: "스테이지파이브, 이노베이션 아카데미와 MOU 체결",
    url: "/press/6",
    date: "2020-12-22",
  },
  {
    title: "“아이폰12 쉽싸빠 할 사람?” 2030 라이프스타일 맞춤형 통신구매 플랫폼 ‘핀다이렉트샵’, 아이폰12 사전예약 실시",
    url: "/press/5",
    date: "2020-10-21",
  },
  {
    title: "스테이지파이브, KT, NUWA ROBOTICS, 아쇼카 한국과 AI 반려로봇 공동 개발을 위한 업무협약 체결",
    url: "/press/4",
    date: "2020-09-17",
  },
  {
    title: "스테이지파이브, 카카오페이 내 '스마트폰샵' 오픈",
    url: "/press/3",
    date: "2020-09-15",
  },
  {
    title: "스마트폰, 이제는 카카오페이로 개통!",
    url: "/press/2",
    date: "2020-08-21",
  },
  {
    title: '스테이지파이브-누와로보틱스…"글로벌 로봇시장 진출"',
    url: "https://www.news1.kr/articles/?3897725",
    date: "2020-04-06",
  },
  {
    title: "스테이지파이브, 글로벌 소셜 로봇 개발사 누와 로보틱스와 전략적 제휴",
    url: "https://www.hankyung.com/it/article/202004068700j",
    date: "2020-04-06",
  },
  {
    title: "카카오 계열사 스테이지파이브, 5G 스마트폰 출시",
    url: "http://www.zdnet.co.kr/view/?no=20191104181038",
    date: "2019-11-04",
  },
  {
    title: "스테이지파이브, 퀄컴 기술력*카카오 서비스 탑재된 5G 디바이스 선보여!",
    url: "http://thekoreanews.com/detail.php?number=62247",
    date: "2019-11-04",
  },
  {
    title: "카카오 계열사, 중저가 '스테이지 5G' 스마트폰 출시",
    url: "https://news.joins.com/article/23623873",
    date: "2019-11-04",
  },
  {
    title: "카카오 계열사, 스마트폰 시장 진출…‘스테이지 5G’ 출시",
    url: "https://cnbc.sbs.co.kr/article/10000961033",
    date: "2019-11-04",
  },
  {
    title: '"미래 ICT, 뭉쳐야 산다"…이통1위-국민메신저 `적과의 동침`',
    url: "https://mk.co.kr/news/it/view/2019/10/882411/",
    date: "2019-10-29",
  },
  {
    title: "카카오 계열사 스테이지파이브, 300억원 투자유치",
    url: "https://www.mk.co.kr/news/stock/view/2019/10/834050/",
    date: "2019-10-15",
  },
  {
    title: "300억 투자 유치한 스테이지파이브 “통신 사업 강화할 것”",
    url: "https://www.venturesquare.net/793364",
    date: "2019-10-15",
  },
  {
    title: "KT, 카카오 공동체 스테이지파이브와 전략적 제휴 체결",
    url: "http://www.ftoday.co.kr/news/articleView.html?idxno=111020",
    date: "2019-08-29",
  },
  {
    title: "KT, 카카오 공동체 스테이지파이브와 5G∙AI 등 미래사업 협력 위한 전략적 제휴",
    url: "http://www.updownnews.co.kr/news/articleView.html?idxno=207778",
    date: "2019-08-29",
  },
  {
    title: "KT, 카카오 계열사 스테이지파이브와 5G∙AI 등 미래사업 협력 전략적 제휴",
    url: "http://www.businesskorea.co.kr/news/articleView.html?idxno=35490",
    date: "2019-08-29",
  },
  {
    title: "KT의 통신 역량과 카카오의 혁신 DNA 합친다",
    url: "http://www.m-i.kr/news/articleView.html?idxno=633955",
    date: "2019-08-29",
  },
  {
    title: "카카오와 만난 KT “매력적 플랫폼의 만남”",
    url: "http://www.econovill.com/news/articleView.html?idxno=370803",
    date: "2019-08-29",
  },
  {
    title: "KT, 카카오 스테이지파이브와 5G·AI 등 미래 사업 협력…“카카오톡으로 KT 모바일 가입”",
    url: "http://cnews.fntimes.com/html/view.php?ud=201908291328485749ee0209bd21_18",
    date: "2019-08-29",
  },
  {
    title: "카카오톡으로 KT 상품 가입하고 카카오 전용 5G 요금제 만든다",
    url: "http://www.dt.co.kr/contents.html?article_no=2019082902109931650002",
    date: "2019-08-29",
  },
  {
    title: "카카오톡서 KT 모바일 가입…카카오 콘텐츠 전용 5G요금제 출시",
    url: "http://news.heraldcorp.com/view.php?ud=20190829000120",
    date: "2019-08-29",
  },
  {
    title: "“카톡에서 KT 요금제 가입”..KT·카카오, 톡 내 메뉴 신설 등 논의",
    url: "https://news.v.daum.net/v/20190829101121726",
    date: "2019-08-29",
  },
  {
    title: "“카카오톡 챗봇”으로 KT 모바일 가입한다",
    url: "https://news.v.daum.net/v/20190829093155802",
    date: "2019-08-29",
  },
]

class PressList extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
  }

  render() {
    let pressNodes = this.props.data.map((press, index) => {
      return (
        <div key={index} className="sf-board-table-row">
          <div className="sf-board-table-cell-1st">
            <a href={press.url} target="_blank" rel="noopener noreferrer">
              {press.title}
            </a>
          </div>
          <div className="sf-board-table-cell-2nd sf-board-table-cell-mobile">
            <span className="sf-icon-arrow-right"></span>
          </div>
          <div className="sf-board-table-cell-2nd sf-board-table-cell-web">{press.date}</div>
        </div>
      )
    })

    return pressNodes
  }
}

class PressPagination extends Component {
  perPage = 5
  state = {
    pageCount: pressData.length / this.perPage,
    data: pressData.slice(0, this.perPage),
    offset: 0,
  }

  handlePageClick = data => {
    const offset = Math.ceil(data.selected * this.perPage)

    this.setState({
      offset: offset,
      data: pressData.slice(offset, offset + this.perPage),
    })
  }

  render() {
    return (
      <div className="content">
        <div className="title">PRESS RELEASE</div>
        <div className="page-area">
          <div className="sf-board-table">
            <div className="sf-board-table-head">
              <div className="sf-board-table-cell">내용</div>
              <div className="sf-board-table-cell">등록일</div>
            </div>
            <PressList data={this.state.data} />
          </div>
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            pageCount={this.state.pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.handlePageClick}
            containerClassName={"Page pagination"}
            activeClassName={"active"}
          />
        </div>
      </div>
    )
  }
}

export default PressPagination
